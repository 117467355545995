import { Browser } from '@capacitor/browser'
import { Anchor, Text, TextProps } from '@mantine/core'
import { isBlogUrl, isShortLink, resolveAndNavigate } from '@util/linkUtils'
import LinkifyIt from 'linkify-it'
import React from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router'
import tlds from 'tlds'

interface LinkedTextProps {
  children: string
  internalDomains?: string[]
}

const DEFAULT_INTERNAL_DOMAINS = [
  'app-int.sway.dm',
  'swaydm.app',
  'sway.dm',
  'app.sway.dm',
]

/**
 * linkify-it instance enhanced with TLDs for better URL detection.
 * 🌐 See: https://data.iana.org/TLD/tlds-alpha-by-domain.txt
 */
const linkify = new LinkifyIt()
linkify.tlds(tlds)

const urlUtils = {
  isInternal: (url: string, allowedDomains: string[]): boolean => {
    try {
      const { host } = new URL(url)
      const currentHost =
        typeof window !== 'undefined' ? window.location.host : ''
      return host === currentHost || allowedDomains.includes(host)
    } catch {
      return false
    }
  },

  stripDomain: (url: string): string => {
    try {
      const { pathname, search, hash } = new URL(url)
      return `${pathname}${search}${hash}`
    } catch {
      return url
    }
  },
}

const linkComponents = {
  blog: (url: string, textUrl: string, idx: number, rest: TextProps) => (
    <Anchor
      key={`${url}-${idx}`}
      component="button"
      onClick={async (e) => {
        e.preventDefault()
        await Browser.open({ url })
      }}
      c={rest.c}
      underline="always"
    >
      {textUrl}
    </Anchor>
  ),

  external: (url: string, textUrl: string, idx: number, rest: TextProps) => (
    <Anchor
      key={`${url}-${idx}`}
      component="button"
      onClick={async (e) => {
        e.preventDefault()
        await Browser.open({ url })
      }}
      c={rest.c}
      underline="always"
    >
      {textUrl}
    </Anchor>
  ),

  internal: (url: string, textUrl: string, idx: number, rest: TextProps) => {
    const path = urlUtils.stripDomain(url)
    return (
      <Anchor
        key={`${url}-${idx}`}
        component={Link}
        to={path || '/'}
        c={rest.c}
        underline="always"
      >
        {textUrl}
      </Anchor>
    )
  },

  short: (
    url: string,
    textUrl: string,
    idx: number,
    navigate: NavigateFunction,
    rest: TextProps
  ) => (
    <Anchor
      key={`${url}-${idx}`}
      component="button"
      onClick={(e) => {
        e.preventDefault()
        resolveAndNavigate(url, navigate)
      }}
      c={rest.c}
      underline="always"
    >
      {textUrl}
    </Anchor>
  ),
}

function getLinkComponent(
  url: string,
  textUrl: string,
  idx: number,
  navigate: NavigateFunction,
  rest: TextProps,
  internalDomains: string[]
) {
  if (isBlogUrl(url)) {
    return linkComponents.blog(url, textUrl, idx, rest)
  }

  if (isShortLink(url)) {
    return linkComponents.short(url, textUrl, idx, navigate, rest)
  }

  if (urlUtils.isInternal(url, internalDomains)) {
    return linkComponents.internal(url, textUrl, idx, rest)
  }

  return linkComponents.external(url, textUrl, idx, rest)
}

export function LinkedText({
  children,
  internalDomains = DEFAULT_INTERNAL_DOMAINS,
  ...rest
}: LinkedTextProps & TextProps) {
  const navigate = useNavigate()

  function parseContent(text: string) {
    const matches = linkify.match(text)
    if (!matches) return [text]

    const nodes: React.ReactNode[] = []
    let lastIndex = 0

    matches.forEach((match, idx) => {
      if (match.index > lastIndex) {
        nodes.push(text.slice(lastIndex, match.index))
      }

      const url = match.url
      const textUrl = match.text
      nodes.push(
        getLinkComponent(url, textUrl, idx, navigate, rest, internalDomains)
      )

      lastIndex = match.lastIndex
    })

    if (lastIndex < text.length) {
      nodes.push(text.slice(lastIndex))
    }
    return nodes
  }

  return (
    <Text
      styles={() => ({
        root: {
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        },
      })}
      {...rest}
    >
      {parseContent(children)}
    </Text>
  )
}
