import { Preferences } from '@capacitor/preferences'
import React, { useCallback, useEffect, useState } from 'react'
import { LayoutContext } from './Layout.context'

export function LayoutProvider({ children }: { children: React.ReactNode }) {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(false)
  const [displayHeader, setDisplayHeader] = useState(true)
  const [displayMobileToolbar, setDisplayMobileToolbar] = useState(true)
  const [isGetAppSheetOpen, setIsGetAppSheetOpen] = useState(false)
  const [isGetAppModalOpen, setIsGetAppModalOpen] = useState(false)
  const [shouldShowAppBanner, setShouldShowAppBanner] = useState(false)

  useEffect(function initLayout() {
    ;(async () => {
      const { value: layoutPreferencesData } = await Preferences.get({
        key: 'layoutPreferences',
      })
      const layoutPreferences = JSON.parse(layoutPreferencesData || '{}')

      if (layoutPreferences.showGetAppSheet !== undefined) {
        setIsGetAppSheetOpen(layoutPreferences.showGetAppSheet)
      } else {
        setIsGetAppSheetOpen(true)
      }

      if (layoutPreferences.showAppBanner !== undefined) {
        setShouldShowAppBanner(layoutPreferences.showAppBanner)
      } else {
        setShouldShowAppBanner(true)
      }
    })()
  }, [])

  const setHeaderTransparent = useCallback(() => {
    setIsHeaderTransparent(true)
  }, [])

  const setHeaderOpaque = useCallback(() => {
    setIsHeaderTransparent(false)
  }, [])

  const hideHeader = useCallback(() => {
    setDisplayHeader(false)
  }, [])

  const showHeader = useCallback(() => {
    setDisplayHeader(true)
  }, [])

  const hideMobileToolbar = useCallback(() => {
    setDisplayMobileToolbar(false)
  }, [])

  const showMobileToolbar = useCallback(() => {
    setDisplayMobileToolbar(true)
  }, [])

  const closeGetAppSheet = useCallback(() => {
    setIsGetAppSheetOpen(false)
    Preferences.set({
      key: 'layoutPreferences',
      value: JSON.stringify({
        showGetAppSheet: false,
        showAppBanner: shouldShowAppBanner,
      }),
    })
  }, [shouldShowAppBanner])

  const openGetAppSheet = useCallback(() => {
    setIsGetAppSheetOpen(true)
  }, [])

  const closeGetAppModal = useCallback(() => {
    setIsGetAppModalOpen(false)
  }, [])

  const openGetAppModal = useCallback(() => {
    setIsGetAppModalOpen(true)
  }, [])

  const handleSetShowAppBanner = useCallback(
    (show: boolean) => {
      setShouldShowAppBanner(show)
      Preferences.set({
        key: 'layoutPreferences',
        value: JSON.stringify({
          showGetAppSheet: isGetAppSheetOpen,
          showAppBanner: show,
        }),
      })
    },
    [isGetAppSheetOpen]
  )

  const memoizedValue = React.useMemo(
    () => ({
      isHeaderTransparent,
      setHeaderTransparent,
      setHeaderOpaque,
      displayHeader,
      hideHeader,
      showHeader,
      displayMobileToolbar,
      hideMobileToolbar,
      showMobileToolbar,
      isGetAppSheetOpen,
      closeGetAppSheet,
      openGetAppSheet,
      isGetAppModalOpen,
      closeGetAppModal,
      openGetAppModal,
      shouldShowAppBanner,
      showShowAppBanner: () => handleSetShowAppBanner(true),
      hideShowAppBanner: () => handleSetShowAppBanner(false),
    }),
    [
      isHeaderTransparent,
      setHeaderTransparent,
      setHeaderOpaque,
      displayHeader,
      hideHeader,
      showHeader,
      displayMobileToolbar,
      hideMobileToolbar,
      showMobileToolbar,
      isGetAppSheetOpen,
      closeGetAppSheet,
      openGetAppSheet,
      isGetAppModalOpen,
      closeGetAppModal,
      openGetAppModal,
      shouldShowAppBanner,
      handleSetShowAppBanner,
    ]
  )

  return (
    <LayoutContext.Provider value={memoizedValue}>
      {children}
    </LayoutContext.Provider>
  )
}
