import { FeatureFlag, useFeaturesQuery } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FeatureFlagContext } from './FeatureFlag.context'
import { FeatureFlagContextBaseState } from './FeatureFlagContextBaseState'

export function FeatureFlagContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { currentUser } = useAuth()

  const { data, refetch, error } = useFeaturesQuery({
    fetchPolicy: 'cache-first',
  })

  if (error) throw error

  const [featureFlags, setFeatureFlags] = useState<{
    allFlags: Array<
      | (FeatureFlag & {
          override?: boolean
          overrideValue?: boolean
        })
      | null
    >
    contentFeed: boolean
    experimentalFeatures: boolean
    hasLoaded: boolean
    knockFeed: boolean
    vendorDashboard: boolean
  }>(FeatureFlagContextBaseState)

  useEffect(() => {
    refetch()
  }, [currentUser, refetch])

  const applyOverrides = useCallback(
    (
      features: Array<FeatureFlag | null> | undefined,
      overrides: { [key: string]: boolean }
    ) => {
      if (features) {
        const updatedFeatures = features.map((flag) => {
          if (flag && overrides[flag.name] !== undefined) {
            return {
              ...flag,
              enabled: overrides[flag.name],
              override: true,
              overrideValue: overrides[flag.name],
            }
          }
          return flag
        })

        const contentFeed = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_content_feed' && flag.enabled
        )
        const experimentalFeatures = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_experimental_features' && flag.enabled
        )
        const knockFeed = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_knock_feed' && flag.enabled
        )
        const vendorDashboard = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_vendor_dashboard' && flag.enabled
        )

        setFeatureFlags((prev) => ({
          ...prev,
          allFlags: updatedFeatures,
          contentFeed,
          experimentalFeatures,
          hasLoaded: true,
          knockFeed,
          vendorDashboard,
        }))
      }
    },
    []
  )

  const setFlagOverride = useCallback(
    (flagName: string, value: boolean) => {
      const userOverrides = localStorage.getItem(
        `featureFlags_${currentUser?.id}`
      )
      let overrides: { [key: string]: boolean } = {}
      if (userOverrides) {
        overrides = JSON.parse(userOverrides) as { [key: string]: boolean }
      }
      overrides[flagName] = value
      localStorage.setItem(
        `featureFlags_${currentUser?.id}`,
        JSON.stringify(overrides)
      )
      applyOverrides(data?.features, overrides)
    },
    [currentUser?.id, applyOverrides, data?.features]
  )

  const removeOverride = useCallback(
    (flagName: string) => {
      const userOverrides = localStorage.getItem(
        `featureFlags_${currentUser?.id}`
      )
      let overrides: { [key: string]: boolean } = {}
      if (userOverrides) {
        overrides = JSON.parse(userOverrides) as { [key: string]: boolean }
      }
      delete overrides[flagName]

      localStorage.setItem(
        `featureFlags_${currentUser?.id}`,
        JSON.stringify(overrides)
      )
      applyOverrides(data?.features, overrides)
    },
    [currentUser?.id, applyOverrides, data?.features]
  )

  const updateFeatureFlags = useCallback(
    (features: Array<FeatureFlag | null>) => {
      const overrides = JSON.parse(
        localStorage.getItem(`featureFlags_${currentUser?.id}`) || '{}'
      )
      applyOverrides(features, overrides)
    },
    [applyOverrides, currentUser?.id]
  )

  useEffect(() => {
    if (currentUser && data) {
      updateFeatureFlags(data.features)
    }

    if (!currentUser) {
      setFeatureFlags(FeatureFlagContextBaseState)
    }
  }, [data, currentUser, applyOverrides, updateFeatureFlags])

  const value = useMemo(
    () => ({
      ...featureFlags,
      removeOverride,
      setFlagOverride,
      updateFeatureFlags,
    }),
    [featureFlags, removeOverride, setFlagOverride, updateFeatureFlags]
  )

  if (!data) {
    return <></>
  }

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
