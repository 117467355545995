import { useFeedItemsQuery } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { useGeolocation } from '@hooks/useGeolocation'
import useFeedStore from '@stores/useFeedStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FeedItemsListContext,
  FeedItemsListProviderProps,
} from './FeedItemsList.context'

export const FeedItemsListProvider = ({
  children,
}: FeedItemsListProviderProps) => {
  const { currentUser } = useAuth()
  const userId = currentUser?.id || ''

  const {
    setFeedInstanceId,
    getUserFeedInstanceId,
    feedsByUserId,
    updateLastSeenItemId: updateStoreLastSeenItemId,
  } = useFeedStore()

  const storedFeedInstanceId = getUserFeedInstanceId(userId)
  const lastSeenItemId = feedsByUserId[userId]?.lastSeenItemId

  const [localItems, setLocalItems] = useState<any[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [feedItemsList, setFeedItemsList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [currentFeedInstanceId, setCurrentFeedInstanceId] = useState<
    string | null
  >(null)

  const updateLastSeenItemId = useCallback(
    (itemId: string) => {
      if (userId && itemId) {
        updateStoreLastSeenItemId(userId, itemId)
      }
    },
    [userId, updateStoreLastSeenItemId]
  )

  const oneHourAgo = useMemo(() => new Date(Date.now() - 60 * 60 * 1000), [])
  const { position } = useGeolocation()

  const geoPoint = useMemo(() => {
    if (position) {
      return {
        geoPoint: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
      }
    } else {
      return {}
    }
  }, [position])

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore,
  } = useFeedItemsQuery({
    variables: {
      includeSeen: true,
      maxItems: 20,
      excludeSeenBefore: oneHourAgo.toISOString(),
      ...geoPoint,
    },
    pollInterval: 0,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (!data?.feedItems?.length) {
        setLocalItems([])
        setHasNextPage(false)
        return
      }

      const newFeedInstanceId = data.feedItems[0]?.feedInstanceId

      if (
        !currentFeedInstanceId ||
        (newFeedInstanceId && currentFeedInstanceId !== newFeedInstanceId)
      ) {
        setLocalItems(data.feedItems)
      }

      if (newFeedInstanceId) {
        setCurrentFeedInstanceId(newFeedInstanceId)
      }

      setHasNextPage((data?.feedItems?.length ?? 0) > 0)
    },
  })

  useEffect(
    function updateFeedInstanceId() {
      if (currentFeedInstanceId && userId) {
        setFeedInstanceId(userId, currentFeedInstanceId)
      }
    },
    [currentFeedInstanceId, userId, setFeedInstanceId]
  )

  const allItems = useMemo(() => {
    if (
      !hasNextPage &&
      localItems[localItems.length - 1]?.id !== 'all-caught-up'
    ) {
      return [...localItems, { id: 'all-caught-up' }]
    }
    return localItems
  }, [localItems, hasNextPage])

  const fetchNextPage = useCallback(
    (feedItemId: string) => {
      fetchMore({
        updateQuery: (
          prev: { feedItems: any[] },
          { fetchMoreResult }: { fetchMoreResult: { feedItems: any[] } }
        ) => {
          if (!fetchMoreResult) {
            setHasNextPage(false)
            return prev
          }

          if (fetchMoreResult.feedItems.length === 0) {
            setHasNextPage(false)
            return prev
          }

          // Check if the new items have the same feed instance ID
          const newFeedInstanceId = fetchMoreResult.feedItems[0]?.feedInstanceId

          if (
            currentFeedInstanceId &&
            newFeedInstanceId &&
            currentFeedInstanceId !== newFeedInstanceId
          ) {
            // If instance ID changed, replace all items
            setCurrentFeedInstanceId(newFeedInstanceId)
            return {
              feedItems: fetchMoreResult.feedItems,
            }
          }

          // Remove duplicates (keeping the earliest item)
          const uniqueItems = [
            ...prev.feedItems,
            ...fetchMoreResult.feedItems,
          ].filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          )

          setLocalItems(uniqueItems)
          setHasNextPage(true)
          return {
            feedItems: uniqueItems,
          }
        },
        variables: {
          afterId: feedItemId,
          includeAfterItemInFeed: false,
        },
      })
    },
    [fetchMore, setHasNextPage, setLocalItems, currentFeedInstanceId]
  )

  const isAllItemsListEmpty = useCallback(() => {
    return allItems.length === 0 || allItems[0]?.id === 'all-caught-up'
  }, [allItems])

  useEffect(() => {
    if (!data || queryLoading || error) return

    setFeedItemsList(data?.feedItems)
    setLoading(queryLoading)
  }, [data, queryLoading, error])

  return (
    <FeedItemsListContext.Provider
      value={{
        loading,
        feedItemsList,
        fetchMoreFeedItems: fetchMore,
        localItems,
        setLocalItems,
        hasNextPage,
        setHasNextPage,
        allItems,
        fetchNextPage,
        isAllItemsListEmpty,
        currentFeedInstanceId,
        storedFeedInstanceId,
        lastSeenItemId,
        userId,
        updateLastSeenItemId,
      }}
    >
      {children}
    </FeedItemsListContext.Provider>
  )
}
