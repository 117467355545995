import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Flex,
  Popover as MantinePopover,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { BOLD, SEMI_BOLD } from '@util/utils'
import React from 'react'

export function SwayPopover({
  opened,
  onClose,
  icon,
  title,
  text,
  children,
}: {
  opened: boolean
  onClose: () => void
  icon: IconProp
  title: string
  text: string
  children: React.ReactNode
}) {
  const theme = useMantineTheme()

  return (
    <MantinePopover
      position="left-end"
      opened={opened}
      width={230}
      withArrow
      zIndex={1}
      styles={{
        arrow: {
          border: `1px solid ${theme.colors.swurple[5]}`,
          scale: '2',
          marginBottom: 8,
        },
        dropdown: {
          border: `1px solid ${theme.colors.swurple[5]}`,
          color: theme.white,
        },
      }}
    >
      <MantinePopover.Dropdown bg="swurple.5">
        <Stack align="center" gap={20}>
          <Flex
            className="absolute bottom-0 top-0 z-0 m-auto h-64 w-64 overflow-hidden opacity-10"
            justify="center"
            align="center"
          >
            <FontAwesomeIcon
              icon={icon}
              color="white"
              style={{ height: '60%', width: '60%' }}
            />
          </Flex>

          <Title fw={BOLD} ta="center" size="lg" order={3}>
            {title}
          </Title>
          <Text ta="center" size="sm" fw={SEMI_BOLD}>
            {text}
          </Text>

          <Stack w="100%" h="100%" justify="flex-end">
            <Button fullWidth color="deep-blue.9" onClick={onClose}>
              Got it!
            </Button>
          </Stack>
        </Stack>
      </MantinePopover.Dropdown>
      <MantinePopover.Target>{children}</MantinePopover.Target>
    </MantinePopover>
  )
}
