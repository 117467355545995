import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import * as CapacitorLiveUpdates from '@capacitor/live-updates'
import { Preferences } from '@capacitor/preferences'
import { Button, Modal, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'

export function LiveUpdates() {
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(function initializeLiveUpdates() {
    const getLiveUpdate = async () => {
      const result = await CapacitorLiveUpdates.sync()
      await Preferences.set({
        key: 'sway-should-update',
        value: result.activeApplicationPathChanged ? 'true' : 'false',
      })

      setShouldUpdate(result.activeApplicationPathChanged)

      App.addListener('resume', async () => {
        console.info('App Resumed, checking for updates')
        const shouldUpdate = await Preferences.get({
          key: 'sway-should-update',
        })

        if (shouldUpdate.value === 'true') {
          await CapacitorLiveUpdates.reload()
        } else {
          const result = await CapacitorLiveUpdates.sync()
          await Preferences.set({
            key: 'sway-should-update',
            value: result.activeApplicationPathChanged ? 'true' : 'false',
          })

          setShouldUpdate(result.activeApplicationPathChanged)
        }
      })
    }

    if (Capacitor.isNativePlatform()) {
      getLiveUpdate()
    }
  }, [])

  return (
    <Modal
      opened={shouldUpdate}
      onClose={close}
      title="New Version of the App Available!"
      closeOnClickOutside={false}
      withCloseButton={false}
    >
      <Stack>
        <Text>There is an update to the app!</Text>
        <Text>
          We've added new features and fixed some bugs. Please press the button
          below to reload the app and apply the updates.
        </Text>
        <Button onClick={() => CapacitorLiveUpdates.reload()}>
          Reload App
        </Button>
      </Stack>
    </Modal>
  )
}
