import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { SwayPopover } from './SwayPopover'

export default function SavePopover({
  inViewport,
  opened,
  children,
  onClose,
  setUserSavedRedemption,
}: {
  inViewport: boolean | undefined
  opened: boolean
  children: React.ReactNode
  onClose: () => void
  setUserSavedRedemption: () => void
}) {
  if (inViewport && opened) {
    return (
      <SwayPopover
        opened={opened}
        onClose={() => {
          onClose()
          setUserSavedRedemption()
        }}
        title={'Save your first redemption!'}
        text={'Save redemptions to your profile for easy access later!'}
        icon={faHeart}
      >
        {children}
      </SwayPopover>
    )
  } else {
    return children
  }
}
