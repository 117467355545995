import { App, AppInfo, AppState } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Device, DeviceInfo } from '@capacitor/device'
import { PushNotifications } from '@capacitor/push-notifications'
import { Badge } from '@capawesome/capacitor-badge'
import { useEffect, useMemo, useState } from 'react'
import { AppContext } from './App.context'

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [currentAppState, setCurrentAppState] = useState<string>('active')
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null)
  const [appInfo, setAppInfo] = useState<AppInfo | null>(null)
  const [appState, setAppState] = useState<AppState | null>(null)

  // The timestamp for when the app was placed in the background
  const [backgroundTimestamp, setBackgroundTimestamp] = useState<number | null>(
    null
  )

  useEffect(() => {
    App.addListener('appStateChange', (state) => {
      setCurrentAppState(state.isActive ? 'active' : 'background')
      if (!state.isActive) {
        setBackgroundTimestamp(Date.now())
      }

      // Reset the badge count when the app is opened
      // ! THIS IS ALSO BEING DONE IN apps/creators/src/context/pushNotifications/PushNotifications.context.tsx
      if (Capacitor.isNativePlatform() && state.isActive) {
        Badge.clear()
        PushNotifications.checkPermissions().then((permissions) => {
          if (permissions.receive === 'granted') {
            PushNotifications.getDeliveredNotifications().then(
              (notifications) => {
                if (notifications.notifications.length > 0) {
                  PushNotifications.removeAllDeliveredNotifications()
                }
              }
            )
          }
        })
      }
    })

    App.getInfo()
      .then((info) => {
        setAppInfo(info)
      })
      .catch(() => {
        console.info('App on web. No Mobile App info.')
      })

    App.getState()
      .then((state) => {
        setAppState(state)
      })
      .catch(() => {
        console.info('App on web. No Mobile App State.')
      })

    Device.getInfo().then((info) => {
      setDeviceInfo(info)
    })

    Device.getId().then((id) => {
      setDeviceId(id.identifier)
    })

    return () => {
      App.removeAllListeners()
    }
  }, [])

  const memoedValue = useMemo(
    () => ({
      appInfo,
      appState,
      backgroundTimestamp,
      currentAppState,
      deviceId,
      deviceInfo,
    }),
    [
      appInfo,
      appState,
      backgroundTimestamp,
      currentAppState,
      deviceId,
      deviceInfo,
    ]
  )

  return (
    <AppContext.Provider value={memoedValue}>{children}</AppContext.Provider>
  )
}
