import { MediaFocalPoint } from '@components/media/Media.types'
import { SwayLoadingOverlay } from '@components/swayStates/SwayLoadingOverlay'
import {
  Broadcast,
  BroadcastInput,
  BroadcastStatus,
  BroadcastTarget,
  MessageType,
  PageFilterOp,
  Redemption,
  RedemptionQueryAttributes,
  User,
  useBroadcastTargetsQuery,
  useBroadcastsQuery,
  useRedemptionsQuery,
  useUserQuery,
} from '@graphql'
import { Box, Stack } from '@mantine/core'
import { zodResolver } from '@mantine/form'
import { useDebouncedValue } from '@mantine/hooks'
import useAuthStore from '@stores/useAuthStore'
import { generateImgixOptions } from '@util/imgixUtils'
import { milesToKm } from '@util/utils'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { BroadcastFormProvider, useBroadcastForm } from '../BroadcastContext'
import { BroadcastFollowUpMessage } from '../BroadcastFollowUpMessage'
import { BroadcastTargetAudience } from '../BroadcastTargetAudience'
import { BroadcastCost } from './BroadcastCost'
import { BroadcastMessage } from './BroadcastMessage'
import { BroadcastMessageType } from './BroadcastMessageType'
import { BroadcastName } from './BroadcastName'
import { BroadcastPreview } from './BroadcastPreview'
import { BroadcastRedemptionOffers } from './BroadcastRedemptionOffers'
import { BroadcastSchedule } from './BroadcastSchedule'
import { BroadcastSendOrUpdate } from './BroadcastSendOrUpdate'
import { MAX_BODY_MESSAGE_SIZE } from './EditBroadcastForm.constants'

const newBroadcastValidation = z.object({
  broadcastName: z
    .string()
    .min(1, { message: 'Please enter a broadcast name' })
    .max(100, { message: 'Broadcast name must be less than 100 characters' }),
  messageTemplate: z.object({
    body: z
      .string()
      .min(1, { message: 'Please enter a message body' })
      .max(MAX_BODY_MESSAGE_SIZE, {
        message: `Message body must be less than ${MAX_BODY_MESSAGE_SIZE} characters`,
      }),
    type: z.enum([MessageType.View, MessageType.Reply], {
      errorMap: (_error) => {
        return {
          message: 'Please select a message type',
        }
      },
    }),
    options: z
      .array(
        z.object({
          text: z.string().min(1, { message: 'Cannot be empty' }),
        })
      )
      .optional(),
  }),
  followUpMessageTemplate: z
    .object({
      body: z.string().max(MAX_BODY_MESSAGE_SIZE, {
        message: `Follow up message body must be less than ${MAX_BODY_MESSAGE_SIZE} characters`,
      }),
      price: z
        .number()
        .min(0.05, { message: 'Price must be at least 0.05' })
        .max(500, { message: 'Price must be no more than 5.0' }),
      options: z.array(
        z.object({
          text: z.string().min(1, { message: 'Cannot be empty' }),
        })
      ),
    })
    .optional(),
  sendImmediately: z.boolean(),
})

export const EditBroadcastForm = ({
  attachedBroadcastImage,
  broadcast,
  broadcastId,
  isBroadcastMultipleChoice = false,
  broadcastStatus,
  loadingBroadcast,
}: {
  broadcast: BroadcastInput | undefined
  broadcastId: string | undefined
  isBroadcastMultipleChoice?: boolean
  attachedBroadcastImage:
    | {
        attachmentId: string
        url: string
      }
    | undefined
  broadcastStatus: BroadcastStatus | undefined
  loadingBroadcast: boolean
}) => {
  const [showMessagePreviewModal, setShowMessagePreviewModal] = useState(false)
  const [radiusMiles, setRadiusMiles] = useState<number | null>(null)
  const [showBroadcastFollowUp, setShowBroadcastFollowUp] = useState(false)
  const [debouncedRadiusMiles] = useDebouncedValue(radiusMiles, 600)
  const [isMultipleChoice, setIsMultipleChoice] = useState(false)
  const [messageTypeSelectValue, setMessageTypeSelectValue] = useState<
    string | null | undefined
  >('')
  const { currentUser } = useAuthStore()
  const isImpersonating = !!currentUser?.impersonator?.id

  const [mediaFocalPoint, setMediaFocalPoint] = useState<
    MediaFocalPoint | undefined
  >({
    x: 0.5,
    y: 0.5,
    z: 1,
  })

  const { data: userQueryData } = useUserQuery({
    fetchPolicy: 'cache-first',
  })
  const { data: broadcastQueryData } = useBroadcastsQuery({
    fetchPolicy: 'cache-first',
  })

  const broadcasts = broadcastQueryData?.broadcasts?.data
  const user = userQueryData?.user

  const { data: broadcastTargetsData, loading: broadcastTargetsLoading } =
    useBroadcastTargetsQuery({
      variables: {
        query: {
          broadcastTarget: debouncedRadiusMiles
            ? {
                allFollowers: false,
                radiusKm: milesToKm(debouncedRadiusMiles),
                usePrimaryLocation: true,
              }
            : { allFollowers: true },
          pageSize: 1,
        },
      },
    })

  const { data: redemptionsData } = useRedemptionsQuery({
    variables: {
      query: {
        limit: 100,
        filters: [
          {
            attribute: RedemptionQueryAttributes.ArchivedAt,
            op: PageFilterOp.Empty,
            value: { boolean: true },
          },
          {
            attribute: RedemptionQueryAttributes.Active,
            op: PageFilterOp.Eq,
            value: { boolean: true },
          },
        ],
      },
      imgixOpts: generateImgixOptions({
        w: 256,
        h: 256,
        fit: 'crop',
        auto: 'compress',
      }),
    },
  })

  const redemptions = redemptionsData?.redemptions?.data as Redemption[]

  const form = useBroadcastForm({
    initialValues: {
      broadcastTarget: null,
      broadcastName: '',
      broadcastStart: new Date().toISOString(),
      followUpMessageTemplate: {
        body: '',
        options: [],
        price: 100,
      },
      messageTemplate: {
        body: '',
        type: null,
        price: 100,
        templateRedemptionOffers: [],
        options: [],
        primaryAttachmentId: attachedBroadcastImage?.attachmentId || null,
        primaryAttachmentDefaultMediaOptions: null,
      },
      sendImmediately: false,
      isPersistent: true,
    },
    validate: zodResolver(newBroadcastValidation),
  })

  useEffect(() => {
    setIsMultipleChoice(isBroadcastMultipleChoice)
  }, [isBroadcastMultipleChoice])

  useEffect(
    function loadFormValuesFromPropsSideEffects() {
      if (broadcast !== undefined) {
        const messageTemplate = broadcast.messageTemplate
        form.setValues({
          ...broadcast,
          messageTemplate: {
            ...messageTemplate,
            templateRedemptionOffers:
              (messageTemplate?.redemptionOffers?.offers
                ?.map((offer) => offer?.redemptionId)
                .filter((offer) => offer !== undefined) as Array<string>) ||
              ([] as Array<string>),
          },
        })

        form.resetDirty({
          ...broadcast,
          messageTemplate: {
            ...messageTemplate,
            templateRedemptionOffers:
              (messageTemplate?.redemptionOffers?.offers
                ?.map((offer) => offer?.redemptionId)
                .filter((offer) => offer !== undefined) as Array<string>) ||
              ([] as Array<string>),
          },
        })

        setMessageTypeSelectValue(getDefaultMessageType(broadcast))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcast]
  )

  function getDefaultMessageType(broadcast: BroadcastInput | undefined) {
    if (broadcast === undefined) {
      return undefined
    }

    if (broadcast.messageTemplate?.type === MessageType.Reply) {
      const optionsLength = broadcast.messageTemplate?.options?.length || 0
      return optionsLength > 0 ? 'Multiple-choice' : 'Reply'
    }

    return 'View'
  }

  const isLiveOrScheduled =
    broadcastStatus &&
    [BroadcastStatus.Live, BroadcastStatus.Scheduled].includes(broadcastStatus)
  {
    /* If Live or Scheduled, the vendor can edit certain broadcast fields */
  }
  if (isLiveOrScheduled) {
    return (
      <Box pos="relative" w="100%">
        <SwayLoadingOverlay visible={loadingBroadcast} />
        <BroadcastFormProvider form={form}>
          <form>
            <Stack mb="xl">
              <BroadcastName form={form} />
              {/* If Persistent or Scheduled, the Message and Multiple choice questions can be edited */}
              {(broadcast?.isPersistent ||
                broadcastStatus === BroadcastStatus.Scheduled) && (
                <>
                  <BroadcastMessage
                    form={form}
                    setShowMessagePreviewModal={setShowMessagePreviewModal}
                  />
                  <BroadcastMessageType
                    form={form}
                    isMultipleChoice={isMultipleChoice}
                    messageTypeSelectValue={messageTypeSelectValue}
                    setIsMultipleChoice={setIsMultipleChoice}
                    setMessageTypeSelectValue={setMessageTypeSelectValue}
                    disableMessageTypeSelector={true}
                  />
                </>
              )}
              <BroadcastSendOrUpdate
                form={form}
                broadcast={broadcast}
                broadcastId={broadcastId}
                mediaFocalPoint={mediaFocalPoint}
                isLiveOrScheduled={isLiveOrScheduled}
              />
            </Stack>
          </form>
        </BroadcastFormProvider>
      </Box>
    )
  }

  return (
    <Box pos="relative" w="100%">
      <SwayLoadingOverlay visible={loadingBroadcast} />
      <BroadcastFormProvider form={form}>
        <form>
          <Stack mb="xl">
            <BroadcastName form={form} />
            <BroadcastMessage
              form={form}
              setShowMessagePreviewModal={setShowMessagePreviewModal}
            />
            <BroadcastMessageType
              form={form}
              isMultipleChoice={isMultipleChoice}
              messageTypeSelectValue={messageTypeSelectValue}
              setIsMultipleChoice={setIsMultipleChoice}
              setMessageTypeSelectValue={setMessageTypeSelectValue}
            />
            <BroadcastRedemptionOffers form={form} redemptions={redemptions} />
            <BroadcastPreview
              form={form}
              showMessagePreviewModal={showMessagePreviewModal}
              mediaFocalPoint={mediaFocalPoint}
              isMultipleChoice={isMultipleChoice}
              setShowMessagePreviewModal={setShowMessagePreviewModal}
              setMediaFocalPoint={setMediaFocalPoint}
              attachedBroadcastImage={attachedBroadcastImage}
              redemptions={redemptions}
            />
            <BroadcastFollowUpMessage
              setShowBroadcastFollowUp={setShowBroadcastFollowUp}
              showBroadcastFollowUp={showBroadcastFollowUp}
              isImpersonating={isImpersonating}
            />
            <BroadcastTargetAudience
              setRadiusMiles={setRadiusMiles}
              formFieldOnChange={form.getInputProps('broadcastTarget').onChange}
              broadcastTarget={
                form.getInputProps('broadcastTarget').value as BroadcastTarget
              }
            />
            <BroadcastCost
              form={form}
              searchingTargetMembers={
                broadcastTargetsLoading || radiusMiles !== debouncedRadiusMiles
              }
              totalNumberOfBroadcastTargets={
                broadcastTargetsData?.broadcastTargets?.page?.totalCount
              }
            />
            <BroadcastSchedule
              form={form}
              broadcasts={broadcasts as Broadcast[]}
              user={user as User}
            />
            <BroadcastSendOrUpdate
              form={form}
              broadcast={broadcast}
              broadcastId={broadcastId}
              mediaFocalPoint={mediaFocalPoint}
            />
          </Stack>
        </form>
      </BroadcastFormProvider>
    </Box>
  )
}
