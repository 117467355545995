import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box } from '@mantine/core'
import { ReactNode } from 'react'

type FeedCardFooterProps = {
  children: ReactNode
  withActionButtonSpace?: boolean
  style?: React.CSSProperties
  className?: string
}

export const FeedCardFooter = ({
  children,
  withActionButtonSpace = true,
  style = {},
  className = '',
  ...restProps
}: FeedCardFooterProps) => {
  const isMobileViewport = useIsMobileViewport()
  return (
    <Box
      pos="absolute"
      w="100%"
      bottom={0}
      pt={16}
      pl={12}
      pb={12}
      pr={withActionButtonSpace ? 76 : 12}
      bg="linear-gradient(to bottom, rgba(0, 0, 0, 0), 40%, rgba(0, 0, 0, 0.9))"
      style={{ ...style }}
      className={`${!isMobileViewport && 'rounded-b-md'} ${className}`}
      {...restProps}
    >
      {children}
    </Box>
  )
}
