import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/sharp-duotone-solid-svg-icons'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Center,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { BOLD, formatUnit, SEMI_BOLD } from '@util/utils'
import React from 'react'
import { ProtectYourStreakSection } from './ProtectYourStreakSection'

interface AnswerStreakStartingModalContentProps {
  streak: number
  goal: number
  onClose(): void
}

export function AnswerStreakStartingModalContent({
  streak,
  goal,
  onClose,
}: AnswerStreakStartingModalContentProps) {
  const theme = useMantineTheme()
  const daysLeftToGoal = goal - streak

  return (
    <Stack justify="space-between" align="center" h="100%" gap={2}>
      <Center style={{ flexGrow: 1 }}>
        <Stack gap={2}>
          <FontAwesomeIcon
            icon={faFire}
            style={
              {
                color: 'inherit',
                height: 120,
                '--fa-primary-color': theme.colors.white,
                '--fa-secondary-color': theme.colors['deep-blue'][9],
                '--fa-primary-opacity': 1,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
          />
          <Stack gap={12}>
            <Text ta="center" fw={BOLD} fz={80}>
              {streak}
            </Text>
            <Text ta="center" fw={BOLD} fz={28}>
              {formatUnit(streak, 'day')} in a row!
            </Text>
            <Text ta="center" fw={BOLD} mt={12}>
              You have {daysLeftToGoal} {formatUnit(daysLeftToGoal, 'day')}{' '}
              until you hit a {goal}-day streak and earn a daily bonus!{' '}
              <span className="inline-block h-4 w-4 rounded-full bg-white">
                <SwayCashIcon className="h-full w-full" />
              </span>
            </Text>
          </Stack>
          <ProtectYourStreakSection onClose={onClose} />
        </Stack>
      </Center>

      <Box px={16} w="100%" fw={SEMI_BOLD}>
        <Button fullWidth color="swurple.9" onClick={onClose}>
          Continue to Feed
        </Button>
      </Box>
    </Stack>
  )
}
