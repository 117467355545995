import { NavigateFunction } from 'react-router'
import { notifications } from './notifications/notifications'

export interface RedirectResponse {
  status: 'ok' | 'error'
  reason?: string
  type?: 'profile' | 'redemption' | 'uri'
  redirect_profile?: {
    id: string
    display_name: string
    profile_type: 'community' | 'community_follower'
  }
  redirect_redemption?: string
  redirect_uri?: {
    host: string
    path: string
    query: string
    fragment: string
  }
}

export const resolveShortLink = async (
  shortCode: string
): Promise<RedirectResponse | null> => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_ROOT_API_SERVER_URL}/links/resolve/${shortCode}`
    )

    if (response.status == 200) {
      return (await response.json()) as RedirectResponse
    } else {
      return null
    }
  } catch (error) {
    console.error('Failed to resolve short link', error)
    notifications.show({
      title: 'Unexpected Error',
      message: 'There was an error resolving this short link.',
      color: 'red',
    })

    throw error
  }
}

export const isShortLink = (url: URL | string) =>
  new URL(url).pathname.startsWith('/s/')
export const getShortCode = (url: URL | string) =>
  new URL(url).pathname.split('/s/').pop() || ''

export const getPathFromShortCode = async (
  url: URL | string
): Promise<string | void> => {
  try {
    const parsedUrl = new URL(url)
    // Extract the short code from the url using pathname so no query params are included.
    const shortCode = getShortCode(parsedUrl)
    const redirect: RedirectResponse | null = await resolveShortLink(shortCode)

    switch (redirect?.type) {
      case 'profile': {
        return `/profile/${redirect.redirect_profile?.display_name}`
      }
      case 'redemption': {
        return `/redemptions/offers/${redirect.redirect_redemption}`
      }
      case 'uri': {
        const uri = redirect.redirect_uri
        return `${uri?.path}${uri?.query ? '?' + uri?.query : ''}${uri?.fragment ? '#' + uri?.fragment : ''}`
      }
      default:
        console.error('Failed to resolve short link', redirect)
        notifications.show({
          title: 'Unexpected Error',
          message: 'There was an error resolving this short link.',
          color: 'red',
        })
        return
    }
  } catch (error) {
    console.error('Failed to get path from short code', error)
    notifications.show({
      title: 'Unexpected Error',
      message: 'There was an error getting the path from this short code.',
      color: 'red',
    })

    throw error
  }
}

export const isBlogUrl = (url: URL | string) => {
  return (
    new URL(url).pathname.startsWith('/blog') &&
    new URL(url).hostname === 'sway.dm'
  )
}

export const resolveAndNavigate = async (
  url: URL | string,
  navigate: NavigateFunction
) => {
  const parsedUrl = new URL(url)
  const shortCode = getShortCode(parsedUrl)
  const path = await getPathFromShortCode(shortCode)

  path && navigate(path)
}

export const trackUri = async (
  uri: string
): Promise<RedirectResponse | null> => {
  try {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_ROOT_API_SERVER_URL}/links/track`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uri }),
        }
      )

      if (response.status == 200) {
        return (await response.json()) as RedirectResponse
      } else {
        return null
      }
    } catch (error) {
      console.error('Failed to track uri', error)

      throw error
    }
  } catch (error) {
    console.error('Failed to track uri', error)

    throw error
  }
}
