import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Group,
  Text,
} from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'

export function ProtectYourStreakSection({ onClose }: { onClose: () => void }) {
  return (
    <Group mt={50} justify="center" wrap="nowrap">
      <Text fw={SEMI_BOLD}>Protect your streak?</Text>
      <Button
        color="swurple"
        variant="filled"
        onClick={onClose}
      >
        Buy Streak Repairs  <FontAwesomeIcon icon={faShieldHalved} size="xl" className="ml-2 inline-block h-4 w-4" />
      </Button>
    </Group>
  )
}