import { Position } from '@capacitor/geolocation'
import {
  MediaOption,
  RedemptionFiltersInput,
  RedemptionOrderByInput,
} from '@graphql'
import { ComboboxItem } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { createContext } from 'react'
import { DEFAULT_REDEMPTION_FILTERS } from './redemptionFilters.constants'

type BuildQueryArguments = (
  formValues: FilterFormValues,
  position?: Position | null,
  imgixOpts?: MediaOption[],
  defaultLimit?: number
) => {
  query: {
    limit?: number
    filters?: RedemptionFiltersInput[]
    orderBy?: RedemptionOrderByInput[]
    offset: number
  }
  currentLocation: {
    latitude: number
    longitude: number
  } | null
  imgixOpts?: MediaOption[]
}

export type FilterFormValues = {
  sortBy?: string | boolean
  priceBetween?: [number, number]
  vendorsId?: string[]
  useCurrentLocation?: boolean
  radiusKm?: number
  onlyDonations?: boolean
  excludeExpired?: boolean
  excludeArchived?: boolean
}

export interface RedemptionFiltersContextType {
  currentFilters: FilterFormValues
  setFilters: React.Dispatch<React.SetStateAction<FilterFormValues>>
  currentSelectedVendorsForFilter: ComboboxItem[]
  setSelectedVendorsForFilter: React.Dispatch<
    React.SetStateAction<ComboboxItem[]>
  >
  generateFlopEntry: (
    key: string,
    value: FilterFormValues[keyof FilterFormValues]
  ) => {
    orderByInput: RedemptionOrderByInput[]
    filtersInput: RedemptionFiltersInput[]
  }
  filtersForm: UseFormReturnType<FilterFormValues>
  defaultFilters: FilterFormValues
  buildQueryArguments: BuildQueryArguments
  filtersLoadedFromUrl: boolean
}

export const RedemptionFiltersContext =
  createContext<RedemptionFiltersContextType>({
    currentFilters: DEFAULT_REDEMPTION_FILTERS,
    setFilters: () => null,
    currentSelectedVendorsForFilter: [],
    setSelectedVendorsForFilter: () => null,
    generateFlopEntry: () => ({ orderByInput: [], filtersInput: [] }),
    filtersForm: {} as UseFormReturnType<FilterFormValues>,
    defaultFilters: DEFAULT_REDEMPTION_FILTERS,
    buildQueryArguments: () => ({
      query: { offset: 0 },
      currentLocation: null,
    }),
    filtersLoadedFromUrl: false,
  })
