// Returns the amount of sway cash bonus earned for the current streak via bonus rewards.
export const getAmountOfSwayCashBonusEarned = (
  overallStreak: number,
  moreThanSevenDaysReward: number = 0.5,
  moreThanFourteenDaysReward: number = 1
) => {
  if (overallStreak < 7) return 0

  if (overallStreak >= 7 && overallStreak < 14)
    return moreThanSevenDaysReward * (overallStreak - 6)

  const daysAfter14 = overallStreak - 13
  const daysBetween7And14 = 7

  if (overallStreak >= 14)
    return (
      daysBetween7And14 * moreThanSevenDaysReward +
      daysAfter14 * moreThanFourteenDaysReward
    )
}
