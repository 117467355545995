import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type UserFeedData = {
  lastSeenItemId: string | undefined
  lastSeenTimestamp: number | undefined
  feedInstanceId: string | undefined | null
}

type FeedState = {
  // Key: userId, Value: user's feed data
  feedsByUserId: Record<string, UserFeedData>
  userSeenSaveRedemptionPopover: Date | null
  userSavedRedemption: Date | null
  userSeenShareRedemptionPopover: Date | null
  userSharedRedemption: Date | null
}

type FeedActions = {
  updateLastSeenItemId: (userId: string, newItemId: string) => void
  setFeedInstanceId: (
    userId: string,
    feedInstanceId: string | undefined | null
  ) => void
  getUserFeedInstanceId: (userId: string) => string | undefined | null
  setUserSeenSaveRedemptionPopover: () => void
  setUserSavedRedemption: () => void
  setUserSeenShareRedemptionPopover: () => void
  setUserSharedRedemption: () => void
}

type FeedStore = FeedState & FeedActions

const useFeedStore = create<FeedStore>()(
  persist(
    (set, get) => ({
      feedsByUserId: {},

      updateLastSeenItemId: (userId, newItemId) => {
        if (!newItemId) return

        const { feedsByUserId } = get()
        const existing = feedsByUserId[userId] ?? {
          lastSeenItemId: undefined,
          lastSeenTimestamp: undefined,
          feedInstanceId: undefined,
        }

        if (existing.lastSeenItemId === newItemId) return

        set({
          feedsByUserId: {
            ...feedsByUserId,
            [userId]: {
              ...existing,
              lastSeenItemId: newItemId,
              lastSeenTimestamp: Date.now(),
            },
          },
        })
      },

      setFeedInstanceId: (userId, feedInstanceId) => {
        if (!userId) return

        const { feedsByUserId } = get()
        const existing = feedsByUserId[userId] ?? {
          lastSeenItemId: undefined,
          lastSeenTimestamp: undefined,
          feedInstanceId: undefined,
        }

        if (existing.feedInstanceId === feedInstanceId) return

        set({
          feedsByUserId: {
            ...feedsByUserId,
            [userId]: {
              ...existing,
              feedInstanceId,
            },
          },
        })
      },

      getUserFeedInstanceId: (userId) => {
        const { feedsByUserId } = get()
        return feedsByUserId[userId]?.feedInstanceId ?? null
      },

      userSeenSaveRedemptionPopover: null,
      setUserSeenSaveRedemptionPopover: () => {
        set({ userSeenSaveRedemptionPopover: new Date() })
      },
      userSavedRedemption: null,
      setUserSavedRedemption: () => {
        set({ userSavedRedemption: new Date() })
      },
      userSeenShareRedemptionPopover: null,
      setUserSeenShareRedemptionPopover: () => {
        set({ userSeenShareRedemptionPopover: new Date() })
      },
      userSharedRedemption: null,
      setUserSharedRedemption: () => {
        set({ userSharedRedemption: new Date() })
      },
    }),
    {
      name: 'feed-store',
      version: 4,
    }
  )
)

export default useFeedStore
