import { TiltedCard } from '@components/tiltedCard/TiltedCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/sharp-duotone-solid-svg-icons'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Center,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { getAmountOfSwayCashBonusEarned } from '@pages/leaderboards/utils/leaderboards.utils'
import { BOLD, formatUnit, SEMI_BOLD } from '@util/utils'
import React from 'react'
import { ProtectYourStreakSection } from './ProtectYourStreakSection'

interface AnswerStreakInProgressModalContentProps {
  streak: number
  moreThanSevenDaysReward: number
  moreThanFourteenDaysReward: number
  onClose(): void
}

export function AnswerStreakInProgressModalContent({
  streak,
  moreThanSevenDaysReward,
  moreThanFourteenDaysReward,
  onClose,
}: AnswerStreakInProgressModalContentProps) {
  const theme = useMantineTheme()
  const amountOfSwayCashBonusEarned =
    getAmountOfSwayCashBonusEarned(
      streak,
      moreThanSevenDaysReward,
      moreThanFourteenDaysReward
    ) || 0
  const hasNotReached14DayStreak = streak < 14

  return (
    <Stack justify="space-between" align="center" h="100%" gap={2}>
      <Center style={{ flexGrow: 1 }}>
        <Stack gap={2}>
          <FontAwesomeIcon
            icon={faFire}
            style={
              {
                color: 'inherit',
                height: 120,
                '--fa-primary-color': theme.colors.white,
                '--fa-secondary-color': theme.colors['deep-blue'][9],
                '--fa-primary-opacity': 1,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
          />
          <Stack gap={4}>
            <Text ta="center" fw={BOLD} fz={80}>
              {streak}
            </Text>
            <Text ta="center" fw={BOLD} fz={28}>
              days in a row!
            </Text>

            {hasNotReached14DayStreak ? (
              <Text ta="center" fw={BOLD}>
                You have {14 - streak} {formatUnit(14 - streak, 'day')} left
                until you hit a 14-day streak!
              </Text>
            ) : (
              <Text ta="center" fw={BOLD}>
                Congratulations, you've reached a {streak}-day streak! 🎉
              </Text>
            )}
          </Stack>

          <Group justify="center">
            <TiltedCard
              backgroundOne={theme.colors.orange[3]}
              ta="center"
              c="white"
              size="lg"
            >
              You earned
              <SwayCashIcon className="ml-1 inline-block h-4 w-4" />{' '}
              {(amountOfSwayCashBonusEarned / 100).toFixed(2)} as a bonus so far
              on your current streak. <br />
              Come back tomorrow for more!
            </TiltedCard>
          </Group>
          <ProtectYourStreakSection onClose={onClose} />
        </Stack>
      </Center>

      <Box px={16} w="100%">
        <Button fullWidth color="swurple.9" onClick={onClose} fw={SEMI_BOLD}>
          Continue to Feed
        </Button>
      </Box>
    </Stack>
  )
}
