import { print } from '@apollo/client/utilities'
import useAppContext from '@context/appContext/useAppContext'
import { LogoutDocument } from '@graphql'
import * as Sentry from '@sentry/react'
import useAuthStore, { UserState } from '@stores/useAuthStore'
import {
  notificationAlreadyAdded,
  notifications,
} from '@util/notifications/notifications'
import { useCallback } from 'react'
import useAnalytics from './useAnalytics'

const ROOT_API_SERVER_URL = import.meta.env.VITE_ROOT_API_SERVER_URL

export const useAuth = () => {
  const analytics = useAnalytics()
  const { deviceId } = useAppContext()
  const currentUser = useAuthStore((state) => state.currentUser)
  const allUsers = useAuthStore((state) => state.allUsers)
  const onLogin = useAuthStore((state) => state.onLogin)
  const onLogout = useAuthStore((state) => state.onLogout)
  const switchUser = useAuthStore((state) => state.switchUser)
  const updateCurrentUser = useAuthStore((state) => state.updateCurrentUser)
  const logoutMutation = print(LogoutDocument)

  const handleLogin = useCallback(
    ({ newUser }: { newUser: UserState }) => {
      const message = `Signed in as ${newUser.displayName}`
      onLogin(newUser)
      !notificationAlreadyAdded(message) &&
        notifications.show({
          message,
          autoClose: 2000,
        })
      analytics.userLogin(
        { userId: newUser.id },
        {
          additional_properties: {
            profile_id: newUser.displayName,
            impersonator_id: newUser.impersonator?.id,
          },
        }
      )
      Sentry.setUser({
        id: newUser.id,
        username: newUser.displayName,
        email: newUser.email,
        ip_address: '{{auto}}',
      })
    },
    [onLogin, analytics]
  )

  const handleLogout = useCallback(
    (
      userId: string | undefined | null,
      options?: { isExpiredSession?: boolean }
    ) => {
      const userIsInAllUsers = allUsers.find((user) => user.id === userId)

      if (!userId || currentUser?.id !== userId || !userIsInAllUsers) {
        return
      }

      onLogout(userId)
      analytics.userLogout(
        { userId },
        {
          additional_properties: {
            profile_id: currentUser?.displayName,
            impersonator_id: currentUser?.impersonator?.id,
          },
        }
      )
      Sentry.setUser(null)

      fetch(`${ROOT_API_SERVER_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken?.value}`,
        },
        credentials: 'include',
        body: JSON.stringify({
          query: logoutMutation,
          variables: { deviceId },
        }),
      }).catch((error) =>
        console.error(
          `Error with logout mutation for user ${currentUser.id}:`,
          error
        )
      )

      const isExpiredSession = options?.isExpiredSession || false
      if (isExpiredSession) {
        const message = 'Your session has expired, please log in again'

        !notificationAlreadyAdded(message) &&
          notifications.show({
            message,
            onClose: () => {
              notifications.cleanQueue()
            },
          })
      } else {
        const message = `Signed out`

        !notificationAlreadyAdded(message) &&
          notifications.show({
            message,
            autoClose: 2000,
          })
      }
    },
    [
      allUsers,
      analytics,
      currentUser?.accessToken?.value,
      currentUser?.id,
      deviceId,
      logoutMutation,
      onLogout,
      currentUser?.displayName,
      currentUser?.impersonator?.id,
    ]
  )

  const handleSwitchUser = useCallback(
    (userId: string) => {
      switchUser(userId)
      const newUser = allUsers.find((user) => user.id === userId)
      analytics.userSwitch(
        {
          fromUserId: currentUser?.id as string,
          toUserId: userId,
        },
        {
          additional_properties: {
            profile_id: newUser?.displayName,
            impersonator_id: newUser?.impersonator?.id,
          },
        }
      )

      if (newUser) {
        Sentry.setUser({
          id: newUser.id,
          username: newUser.displayName,
          email: newUser.email,
          ip_address: '{{auto}}',
        })
      }

      const isExpiredSession =
        new Date(newUser?.accessToken?.validUntil || '').getTime() < Date.now()

      if (!isExpiredSession) {
        const message = `Switched to ${newUser?.displayName}`
        !notificationAlreadyAdded(message) &&
          notifications.show({
            message,
            autoClose: 2000,
          })
      }
    },
    [allUsers, analytics, currentUser?.id, switchUser]
  )

  const handleUpdateCurrentUser = useCallback(
    ({ updatedUserProps }: { updatedUserProps: Partial<UserState> }) => {
      updateCurrentUser(updatedUserProps)
    },
    [updateCurrentUser]
  )

  return {
    currentUser,
    allUsers,
    handleLogin: handleUpdateCurrentUser,
    onLogin: handleLogin,
    onLogout: handleLogout,
    switchUser: handleSwitchUser,
    updateCurrentUser: handleUpdateCurrentUser,
  }
}
