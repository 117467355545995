import { createContext } from 'react'

export type FeedItemsListProviderProps = {
  children: React.ReactNode
}

type FeedItemsListProviderState = {
  loading: boolean
  feedItemsList: any[]
  fetchMoreFeedItems: (options: any) => any
  localItems: any[]
  setLocalItems: (localItems: any[]) => void
  hasNextPage: boolean
  setHasNextPage: (hasNextPage: boolean) => void
  allItems: any[]
  fetchNextPage: (feedItemId: string) => void
  isAllItemsListEmpty: () => boolean
  currentFeedInstanceId: string | null
  storedFeedInstanceId: string | null | undefined
  lastSeenItemId: string | undefined
  userId: string
  updateLastSeenItemId: (itemId: string) => void
}

const initialState = {
  loading: false,
  feedItemsList: [],
  fetchMoreFeedItems: () => null,
  localItems: [],
  setLocalItems: () => null,
  hasNextPage: false,
  setHasNextPage: () => null,
  allItems: [],
  fetchNextPage: () => null,
  isAllItemsListEmpty: () => false,
  currentFeedInstanceId: null,
  storedFeedInstanceId: null,
  lastSeenItemId: undefined,
  userId: '',
  updateLastSeenItemId: () => null,
}

export const FeedItemsListContext =
  createContext<FeedItemsListProviderState>(initialState)
