import { useFeatureFlags } from '@context/index'
import { Redirect } from '../Redirect'

export function RequireFeatureFlag({
  flag,
  children,
}: {
  flag: string
  children: React.ReactNode
}) {
  const { allFlags } = useFeatureFlags()

  const featureFlag = allFlags.find((f) => f?.name === flag)

  if (!featureFlag || !featureFlag.enabled) {
    return <Redirect to="/" />
  }

  return <>{children}</>
}
