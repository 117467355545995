import { AnswerStreakStartingModalContent } from './AnswerStreakStartingModalContent'

import { modals } from '@mantine/modals'
import { AnswerStreakGoalReachedModalContent } from './AnswerStreakGoalReachedModalContent'
import { AnswerStreakInProgressModalContent } from './AnswerStreakInProgressModalContent'

export function AnswerStreakDailyModal({
  goal,
  hasReachedAGoal,
  streak,
  moreThanSevenDaysReward,
  moreThanFourteenDaysReward,
}: {
  goal: number
  hasReachedAGoal: boolean
  streak: number
  moreThanSevenDaysReward: number
  moreThanFourteenDaysReward: number
}) {
  if (hasReachedAGoal) {
    return (
      <AnswerStreakGoalReachedModalContent
        streak={streak}
        moreThanSevenDaysReward={moreThanSevenDaysReward}
        moreThanFourteenDaysReward={moreThanFourteenDaysReward}
        onClose={() => modals.closeAll()}
      />
    )
  }

  if (streak < goal) {
    return (
      <AnswerStreakStartingModalContent
        streak={streak}
        goal={goal}
        onClose={() => modals.closeAll()}
      />
    )
  }

  return (
    <AnswerStreakInProgressModalContent
      streak={streak}
      moreThanSevenDaysReward={moreThanSevenDaysReward}
      moreThanFourteenDaysReward={moreThanFourteenDaysReward}
      onClose={() => modals.closeAll()}
    />
  )
}
