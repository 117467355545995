import { useContext } from 'react'
import { FeedItemsListContext } from './FeedItemsList.context'

export const useFeedItemsListContext = () => {
  const context = useContext(FeedItemsListContext)

  if (context === undefined)
    throw new Error(
      'useFeedItemsListContext must be used within a FeedItemsListProvider'
    )

  return context
}
