import {
  Barcode,
  CameraPermissionState,
} from '@capacitor-mlkit/barcode-scanning'
import { noop } from 'lodash-es'
import { createContext } from 'react'

type CameraContextProps = {
  startScan: () => Promise<void>
  stopScan: () => Promise<void>
  scanSingleBarcode: () => Promise<Barcode>
  scan: () => Promise<Barcode[]>
  isSupported: () => Promise<boolean>
  openSettings: () => Promise<void>
  isGoogleBarcodeScannerModuleAvailable: () => Promise<boolean>
  installGoogleBarcodeScannerModule: () => Promise<void>
  checkPermissions: () => Promise<CameraPermissionState>
  requestPermissions: () => Promise<CameraPermissionState>
}

export const CameraContext = createContext<CameraContextProps>({
  startScan: async () => {
    noop()
  },
  stopScan: async () => {
    noop()
  },
  scanSingleBarcode: async () => {
    return {} as Barcode
  },
  scan: async () => [],
  isSupported: async () => false,
  openSettings: async () => {
    noop()
  },
  isGoogleBarcodeScannerModuleAvailable: async () => false,
  installGoogleBarcodeScannerModule: async () => {
    noop()
  },
  checkPermissions: async () => {
    return {} as CameraPermissionState
  },
  requestPermissions: async () => {
    return {} as CameraPermissionState
  },
})
