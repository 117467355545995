import { useApolloClient } from '@apollo/client'
import { AppStoreLinks } from '@components/mobileAppPrompts/AppStoreLinks'
import useAppContext from '@context/appContext/useAppContext'
import { useFeatureFlags } from '@context/index'
import { LoginForm } from '@forms/LoginForm'
import {
  FeaturesDocument,
  FeaturesQuery,
  ProfileType,
  useLoginMutation,
  useResendEmailVerificationMutation,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { AppShell, Center, Container, Modal, Paper, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import * as Sentry from '@sentry/react'
import { notifications } from '@util/notifications/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { z } from 'zod'
import { UnconfirmedEmailCard } from './components/UnconfirmedEmailCard'

const DEFAULT_USERNAME = import.meta.env.VITE_ROOT_DEFAULT_USERNAME
const DEFAULT_PASSWORD = import.meta.env.VITE_ROOT_DEFAULT_PASSWORD

const loginSchemaValidation = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(80),
})

export const LoginPage = () => {
  const { deviceInfo } = useAppContext()
  const navigate = useNavigate()
  const [showUnconfirmedEmailMessage, setShowUnconfirmedEmailMessage] =
    useState<{ email: string; sent: boolean } | null>(null)
  const { updateFeatureFlags } = useFeatureFlags()

  const { currentUser, onLogin } = useAuth()

  const client = useApolloClient()

  const [login, { loading }] = useLoginMutation()
  const [
    resendEmailVerificationMutation,
    { loading: resendEmailVerificaitonLoading },
  ] = useResendEmailVerificationMutation()

  const form = useForm<{
    email: string
    password: string
  }>({
    initialValues: {
      email: DEFAULT_USERNAME || '',
      password: DEFAULT_PASSWORD || '',
    },
    validate: zodResolver(loginSchemaValidation),
  })

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    try {
      const { data } = await login({ variables: { email, password } })

      if (data?.login.user && data.login.user.id) {
        const { accessToken, user, impersonator } = data.login
        const userId = data.login.user.id

        Sentry.setUser({
          id: userId,
          email: email,
        })

        if (!accessToken?.value) {
          throw new Error('No access token returned')
        }

        const { data: featuresData } = await client.query<FeaturesQuery>({
          query: FeaturesDocument,
          fetchPolicy: 'network-only', // Ensure we have the latest
          context: {
            headers: {
              Authorization: `Bearer ${accessToken.value}`,
            },
          },
        })

        updateFeatureFlags(featuresData.features)

        onLogin({
          newUser: {
            id: userId,
            accessToken: accessToken,
            communityName: user?.profile?.communityName || '',
            displayName: user?.profile?.displayName || '',
            email: email,
            firstName: user?.profile?.firstName || '',
            lastName: user?.profile?.lastName || '',
            profileType: user?.profile?.profileType as ProfileType,
            profilePictureUrl: user?.profile?.profilePhotoUrl || '',
            profileId: user?.profile?.id,
            impersonator:
              impersonator && impersonator.id && impersonator.email
                ? {
                    id: impersonator.id,
                    email: impersonator.email,
                  }
                : undefined,
          },
        })

        navigate('/')
      } else {
        form.setErrors({ password: 'Invalid email or password' })
      }
    } catch (err: any) {
      if (err.message === 'unconfirmed email') {
        setShowUnconfirmedEmailMessage({ email: email, sent: false })
      } else {
        console.error('[Login Page] Submit Error: ', err)
      }

      form.setErrors({ email: ' ', password: 'Invalid email or password' })
      notifications.show({
        title: 'Error attempting to login',
        message: err.message,
        autoClose: 5000,
      })
    }
  }

  const resendVerificationEmail = useCallback(async () => {
    try {
      await resendEmailVerificationMutation({
        variables: { email: showUnconfirmedEmailMessage?.email || '' },
      })

      setShowUnconfirmedEmailMessage({
        email: showUnconfirmedEmailMessage?.email || '',
        sent: true,
      })

      notifications.show({
        title: 'Success',
        message: `We've sent a new verification email to ${showUnconfirmedEmailMessage?.email}`,
        autoClose: 5000,
      })
    } catch (err: any) {
      console.error('[Login Page] Resend Error: ', err)
      notifications.show({
        title: 'Error attempting to resend verification email',
        message: err.message,
        autoClose: 5000,
      })
    }
  }, [resendEmailVerificationMutation, showUnconfirmedEmailMessage?.email])

  useEffect(() => {
    if (currentUser && currentUser.accessToken) {
      navigate('/')
    }
  }, [currentUser, navigate])

  return (
    <AppShell.Main>
      <Center h="100%">
        <Container size={420} my={40}>
          <Title fw={900} ta="center">
            Welcome Back!
          </Title>

          <Paper shadow="sm" p={30} mt={30} radius="md" withBorder>
            <LoginForm form={form} loading={loading} onSubmit={onSubmit} />
            {deviceInfo?.platform === 'web' && <AppStoreLinks />}
          </Paper>
        </Container>
      </Center>
      <Modal
        opened={!!showUnconfirmedEmailMessage}
        onClose={() => setShowUnconfirmedEmailMessage(null)}
      >
        {showUnconfirmedEmailMessage && (
          <UnconfirmedEmailCard
            showUnconfirmedEmailMessage={showUnconfirmedEmailMessage}
            resendEmailVerificaitonLoading={resendEmailVerificaitonLoading}
            resendVerificationEmail={resendVerificationEmail}
          />
        )}
      </Modal>
    </AppShell.Main>
  )
}
