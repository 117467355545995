import { useFeedItemsListContext } from '@context/feedItemsListContext/useFeedItemsListContext'
import { useFeedItemSeenMutation } from '@graphql'
import useAnalytics, { feedItemTypeToImpressionType } from '@hooks/useAnalytics'
import { useAuth } from '@hooks/useAuth'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Flex } from '@mantine/core'
import { forwardRef, RefObject, useEffect, useRef, useState } from 'react'

interface FeedCardProps {
  itemId: string
  itemType: string
  setInViewportCardProps: (
    props: {
      itemId: string
      itemType: string
      seenAt: number
    } | null
  ) => void
  children: React.ReactNode
}

const FEED_ITEM_IMPRESSION_THRESHOLD_MS = 50
const FEED_ITEM_SEEN_THRESHOLD_MS = 500

export const FeedCard = forwardRef<HTMLDivElement, FeedCardProps>(
  ({ itemId, itemType, setInViewportCardProps, children }, ref) => {
    // ? 📝 We need to use a useRef here as otherwise the state will update too frequently,
    // ?    and this is used primarily for reporting analytics data, not stateful updates
    const feedItemSeenState = useRef<{
      seen: boolean
      startTime: Date | undefined
      reported: boolean
    }>({
      seen: false,
      startTime: undefined,
      reported: false,
    })

    const { currentUser } = useAuth()
    const { updateLastSeenItemId } = useFeedItemsListContext()
    const { feedItemAction } = useAnalytics()

    const isMobileViewport = useIsMobileViewport()

    const elementRef = useRef<HTMLDivElement>(null)
    const inViewport = useIntersectionObserver(elementRef, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.9, // Trigger when 10% of the element is visible
    })

    const [seenFeedItem] = useFeedItemSeenMutation()

    useEffect(
      function reportIfSeen() {
        // If the item is in the viewport, update the last seen item ID so if the user leaves the page and then come back, they will see the correct feed item
        if (inViewport) {
          updateLastSeenItemId(itemId)
        }

        if (inViewport && !feedItemSeenState.current.seen) {
          const startTime = new Date()

          feedItemSeenState.current = {
            ...feedItemSeenState.current,
            seen: true,
            startTime: startTime,
            reported: false,
          }

          setInViewportCardProps({
            itemId,
            itemType,
            seenAt: startTime.getTime(),
          })
        } else if (
          !inViewport &&
          feedItemSeenState.current.seen &&
          !feedItemSeenState.current.reported &&
          feedItemSeenState.current.startTime
        ) {
          const deltaMs =
            new Date().getTime() - feedItemSeenState.current.startTime.getTime()

          if (
            deltaMs < FEED_ITEM_IMPRESSION_THRESHOLD_MS &&
            currentUser?.profileId
          ) {
            feedItemAction({
              itemId: itemId,
              type: feedItemTypeToImpressionType[
                itemType as keyof typeof feedItemTypeToImpressionType
              ],
              action: 'viewed',
              profileId: currentUser.profileId,
              durationMs: deltaMs,
            })
          }

          if (deltaMs < FEED_ITEM_SEEN_THRESHOLD_MS) return

          seenFeedItem({
            variables: {
              feedItemSeenId: itemId,
              deltaMs,
            },
          })

          feedItemSeenState.current.reported = true
        }
      },
      [
        currentUser?.profileId,
        inViewport,
        itemId,
        seenFeedItem,
        updateLastSeenItemId,
        itemType,
        feedItemAction,
        setInViewportCardProps,
      ]
    )

    if (!isMobileViewport && isMobileViewport !== undefined) {
      return (
        <Flex
          ref={ref}
          justify="center"
          pos="relative"
          h="100%"
          w={isMobileViewport ? '100%' : undefined}
        >
          <Box
            ref={elementRef}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              overflow: 'hidden',
              aspectRatio: '9 / 16',
              border: '4px solid #000',
            }}
            className="rounded-xl border-4 border-black shadow-xl"
          >
            {children}
          </Box>
        </Flex>
      )
    }

    return (
      <Box
        ref={ref}
        style={{
          height: 'calc(100vh - var(--app-shell-footer-height))',
          scrollSnapAlign: 'start',
          position: 'relative',
        }}
        w="100%"
      >
        <Box
          ref={elementRef}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    )
  }
)

interface IntersectionObserverArgs extends IntersectionObserverInit {
  root?: Element | Document | null
  rootMargin?: string
  threshold?: number | number[]
}

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  options: IntersectionObserverArgs
): boolean => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    }, options)

    const currentElement = elementRef.current
    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, [elementRef, options])

  return isVisible
}
