import { ProfileType, RedemptionLimitPartsFragment } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Center, Group, Stack, Text, Title } from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Link } from 'react-router'
import { RedemptionImage } from './RedemptionImage'
dayjs.extend(duration)

export const RedemptionMainPreview = ({
  vendor,
  image,
  name,
  ownerProfileId,
  price,
  validEnd,
  distance,
  hasExpired,
  isArchived,
  isFunded,
  limits,
  linkedProfileDisplayName,
  linkedProfileId,
  linkedProfilePhotoUrl,
  saved,
  showSaveSection = true,
  handleSaveRedemption,
  handleUnsaveRedemption,
}: {
  vendor: {
    name: string
    displayName: string
  }
  image: {
    src: string
    srcSet: string
  }
  name: string
  ownerProfileId: string
  price: {
    amount: string | number
  }
  validEnd: string | undefined | null
  distance?: string
  hasExpired?: boolean
  isArchived?: boolean
  isFunded?: boolean
  limits?: RedemptionLimitPartsFragment[]
  linkedProfileDisplayName?: string | null
  linkedProfileId?: string | null
  linkedProfilePhotoUrl?: string | null
  saved?: boolean
  showSaveSection?: boolean
  handleSaveRedemption?: () => void
  handleUnsaveRedemption?: () => void
}) => {
  const { currentUser } = useAuth()

  const isCurrentUser = !!currentUser

  const isVendor = currentUser?.profileType === ProfileType.Community

  const src = image.src
  const srcSet = image.srcSet

  return (
    <Stack>
      <Center className="overflow-hidden">
        <Box pos="relative">
          <RedemptionImage
            src={src || undefined}
            srcSet={srcSet || undefined}
            isExpired={hasExpired}
            isFunded={isFunded}
            isArchived={isArchived}
            limits={limits}
            validEnd={validEnd}
            linkedProfileId={linkedProfileId}
            linkedProfilePhotoUrl={linkedProfilePhotoUrl}
            linkedProfileDisplayName={linkedProfileDisplayName}
            ownerProfileId={ownerProfileId}
            saved={saved}
            handleSaveRedemption={handleSaveRedemption}
            handleUnsaveRedemption={handleUnsaveRedemption}
            showSaveSection={showSaveSection && isCurrentUser && !isVendor}
          />
        </Box>
      </Center>

      <Stack w="100%">
        <Box>
          <Title order={4} className="line-clamp-2">
            {name}
          </Title>
          <Group align="center">
            <Text size="sm">
              By&nbsp;
              <Link to={`/profile/${vendor.displayName}`}>{vendor.name}</Link>
            </Text>
            {distance && (
              <Text size="sm" c="dimmed">
                {distance}
              </Text>
            )}
          </Group>
        </Box>

        {price.amount && (
          <Group gap={0}>
            <SwayCashIcon className="h-4 w-4 rounded-xl bg-white" />
            <Text fw={SEMI_BOLD}>&nbsp;{price.amount}</Text>
          </Group>
        )}
      </Stack>
    </Stack>
  )
}
