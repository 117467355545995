import { useApolloClient } from '@apollo/client'
import {
  NotificationPreferences,
  NotificationPreferencesPageDocument,
  NotificationPreferencesPageQuery,
  useNotificationPreferencesPageQuery,
  User,
  useSetNotificationPreferencesMutation,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { useCallback, useMemo } from 'react'
import { NotificationSettingsContext } from './NotificationSettings.context'
import { transformNotificationPreferences } from './notificationUtils'

export function NotificationSettingsContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const client = useApolloClient()
  const { allUsers, currentUser } = useAuth()

  const { data, loading, refetch } = useNotificationPreferencesPageQuery({
    fetchPolicy: 'cache-and-network',
    skip: !currentUser,
  })

  const [updateNotificationPreferences] =
    useSetNotificationPreferencesMutation()
  // const [setDefaultNotificationPreferencesForMobile] =
  //   useSetDefaultNotificationPreferencesForMobileMutation({
  //     refetchQueries: ['NotificationPreferencesPage'],
  //   })

  const updateNotificationPreferencesCallback = useCallback(
    (input: NotificationPreferences) => {
      updateNotificationPreferences({
        variables: {
          np: transformNotificationPreferences(input),
        },
        optimisticResponse: {
          setNotificationPreferences: {
            ...input,
          },
        },
      })
    },
    [updateNotificationPreferences]
  )

  const toggleChannelPreference = useCallback(
    (channelId: string) => {
      let channels = data?.notificationPreferences?.channels || []
      let workflows = data?.notificationPreferences?.workflows || []

      channels = channels.map((c) => {
        if (!c) return c
        return c.id === channelId ? { ...c, enabled: !c.enabled } : c
      })

      const changedChannel = channels.find((c) => c?.id === channelId)

      workflows = workflows.map((w) => {
        if (!w) return w
        const channelPreferences = w.channelPreferences.map((c) => {
          if (!c) return c
          return c.type === changedChannel?.type
            ? { ...c, enabled: changedChannel?.enabled }
            : c
        })
        return {
          ...w,
          channelPreferences,
        }
      })

      updateNotificationPreferencesCallback({
        channels,
        workflows,
      })
    },
    [data?.notificationPreferences, updateNotificationPreferencesCallback]
  )

  const toggleWorkflowPreference = useCallback(
    (workflowId: string) => {
      let workflows = data?.notificationPreferences?.workflows || []
      const channels = data?.notificationPreferences?.channels || []

      workflows = workflows.map((w) => {
        if (!w) return w
        if (w.id === workflowId) {
          const channelPreferences = w.channelPreferences.map((c) => {
            if (!c) return c
            const channel = channels.find((ch) => ch?.type === c.type)
            return channel ? { ...c, enabled: !w.enabled } : c
          })
          return {
            ...w,
            enabled: !w.enabled,
            channelPreferences,
          }
        } else {
          return w.id === workflowId ? { ...w, enabled: !w.enabled } : w
        }
      })

      updateNotificationPreferencesCallback({
        channels,
        workflows,
      })
    },
    [
      data?.notificationPreferences?.channels,
      data?.notificationPreferences?.workflows,
      updateNotificationPreferencesCallback,
    ]
  )

  const toggleWorkflowChannelPreference = useCallback(
    (workflowId: string, channelType: string) => {
      let workflows = data?.notificationPreferences?.workflows || []
      const channels = data?.notificationPreferences?.channels || []

      workflows = workflows.map((w) => {
        if (!w || w.id !== workflowId) return w
        let channelIsEnabled = false
        const channelPreferences = w.channelPreferences.map((c) => {
          if (!c || c.type !== channelType) return c
          channelIsEnabled = !c.enabled
          return {
            ...c,
            enabled: !c.enabled,
          }
        })

        if (channelIsEnabled) {
          return {
            ...w,
            enabled: true,
            channelPreferences,
          }
        } else {
          return {
            ...w,
            channelPreferences,
          }
        }
      })

      const updatedChannels = channels.map((c) => {
        if (!c || c.type !== channelType) return c
        return {
          ...c,
          enabled: true,
        }
      })

      updateNotificationPreferencesCallback({
        channels: updatedChannels,
        workflows,
      })
    },
    [
      data?.notificationPreferences?.channels,
      data?.notificationPreferences?.workflows,
      updateNotificationPreferencesCallback,
    ]
  )

  // 🤝 Helper Functions
  const getNotificationPreferencesForUser = useCallback(
    async (userId: string) => {
      const user = allUsers.find((u) => u.id === userId)

      const { data } = await client.query<NotificationPreferencesPageQuery>({
        context: {
          headers: {
            authorization: `Bearer ${user?.accessToken?.value}`,
          },
        },
        query: NotificationPreferencesPageDocument,
        variables: {
          userId,
        },
      })

      return data
    },
    [allUsers, client]
  )

  const memoedValue = useMemo(
    () => ({
      notificationPreferences: data?.notificationPreferences,
      user: data?.user as User,
      smsVerificationCurrent: data?.smsVerificationCurrent,
      getNotificationPreferencesForUser,
      loading,
      refetch,
      toggleChannelPreference,
      toggleWorkflowPreference,
      toggleWorkflowChannelPreference,
    }),
    [
      data?.notificationPreferences,
      data?.user,
      data?.smsVerificationCurrent,
      getNotificationPreferencesForUser,
      loading,
      refetch,
      toggleChannelPreference,
      toggleWorkflowPreference,
      toggleWorkflowChannelPreference,
    ]
  )

  return (
    <NotificationSettingsContext.Provider value={memoedValue}>
      {children}
    </NotificationSettingsContext.Provider>
  )
}
