import { Capacitor } from '@capacitor/core'
import { faCopy, faShareFromSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAnalytics from '@hooks/useAnalytics'
import { Button, Center, Divider, Modal, Stack } from '@mantine/core'
import { handleCopyUrl, shareItem } from '@util/utils'
import { QRCodeCanvas } from 'qrcode.react'
import { QRCodeEntitySharing, ShareSource } from '../../../constants'

export const ShareQrCodeModal = ({
  url,
  type,
  title,
  communityName,
  qrCodeEntitySharing,
  shareSource = ShareSource.ENTITY_PAGE,
  open,
  setOpen,
  children,
}: {
  url: string
  type: string
  title?: string
  communityName?: string
  // The entity that is being shared (Redemption, Profile, etc.)
  qrCodeEntitySharing: QRCodeEntitySharing
  shareSource?: ShareSource
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}) => {
  const { track } = useAnalytics()

  return (
    <Modal
      title={title}
      opened={open}
      onClose={() => setOpen(false)}
      className="bg-white"
    >
      <Center>
        <Stack>
          <Stack align="center">
            <QRCodeCanvas
              value={url}
              size={300}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              marginSize={0}
            />
          </Stack>
          <Divider size="md" my="xs" label="OR" labelPosition="center" />
          <Stack>
            <Button
              className="flex w-full items-center justify-center"
              variant="outline"
              color="swurple"
              onClick={() =>
                shareItem(
                  qrCodeEntitySharing,
                  shareSource,
                  track,
                  url,
                  title,
                  communityName,
                  type
                )
              }
            >
              <FontAwesomeIcon icon={faShareFromSquare} className="mr-1" />
              Share
            </Button>
            {!Capacitor.isNativePlatform() && (
              <Button
                className="flex w-full items-center justify-center"
                variant="outline"
                color="swurple"
                onClick={() =>
                  handleCopyUrl(
                    url,
                    qrCodeEntitySharing,
                    shareSource,
                    track,
                    type
                  )
                }
              >
                <FontAwesomeIcon icon={faCopy} className="mr-1" />
                Copy Link
              </Button>
            )}
          </Stack>
          {children}
        </Stack>
      </Center>
    </Modal>
  )
}
