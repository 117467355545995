import {
  PageFilterOp,
  PageFilterValues,
  PageOrderDirection,
  RedemptionFiltersInput,
  RedemptionOrderByInput,
  RedemptionQueryAttributes,
} from '@graphql'
import { milesToKm } from '@util/utils'
import {
  FILTERS,
  MAX_FILTER_PRICE,
  MIN_FILTER_PRICE,
  SORT_BY,
  SWAYCASH_CENT_CONVERSION_FACTOR,
} from './redemptionFilters.constants'
import { FilterFormValues } from './RedemptionFilters.context'

export const generateFlopEntry = (
  key: string,
  value: FilterFormValues[keyof FilterFormValues]
): {
  orderByInput: RedemptionOrderByInput[]
  filtersInput: RedemptionFiltersInput[]
} => {
  const orderByInput: RedemptionOrderByInput[] = []
  const filtersInput: RedemptionFiltersInput[] = []

  switch (key) {
    case FILTERS.SORT_BY: {
      const sortByValue = value as string
      if (sortByValue) {
        sortByValue && orderByInput.push(sortValues[sortByValue as string])
      }
      break
    }
    case FILTERS.PRICE_BETWEEN: {
      const priceBetween = value as [number, number]
      if (priceBetween) {
        const [min, max] = priceBetween

        const minFilter =
          min !== MIN_FILTER_PRICE &&
          generateFlopFilter(
            RedemptionQueryAttributes.Price,
            PageFilterOp.GtEq,
            { integer: min * SWAYCASH_CENT_CONVERSION_FACTOR }
          )

        const maxFilter =
          max !== MAX_FILTER_PRICE &&
          generateFlopFilter(
            RedemptionQueryAttributes.Price,
            PageFilterOp.LtEq,
            { integer: max * SWAYCASH_CENT_CONVERSION_FACTOR }
          )

        minFilter && filtersInput.push(minFilter)
        maxFilter && filtersInput.push(maxFilter)
      }
      break
    }

    case FILTERS.VENDORS_ID: {
      const filterByValue = value as string[]

      filterByValue.length > 0 &&
        filtersInput.push({
          attribute: RedemptionQueryAttributes.CommunityId,
          op: PageFilterOp.In,
          value: { strings: filterByValue },
        })
      break
    }

    case FILTERS.FULL_TEXT: {
      const fullTextValue = value as string
      if (fullTextValue) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.FullText,
            PageFilterOp.Contains,
            { string: fullTextValue }
          )
        )
      }
      break
    }

    case FILTERS.RADIUS_KM: {
      const radiusValueInMiles = value === 100 ? 100 : (value as number) / 10
      const radiusValueInKm = milesToKm(radiusValueInMiles)
      if (radiusValueInKm) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.RadiusKm,
            PageFilterOp.LtEq,
            { float: radiusValueInKm }
          )
        )
      }

      break
    }

    case FILTERS.ONLY_DONATIONS: {
      const onlyNonProfit = value as boolean
      if (onlyNonProfit) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.IsNonProfit,
            PageFilterOp.Eq,
            { boolean: true }
          )
        )
      }
      break
    }

    case FILTERS.EXCLUDE_EXPIRED: {
      filtersInput.push(
        generateFlopFilter(
          RedemptionQueryAttributes.GlobalLimitReached,
          PageFilterOp.Eq,
          { boolean: false }
        )
      )
      filtersInput.push(
        generateFlopFilter(
          RedemptionQueryAttributes.ValidDateRange,
          PageFilterOp.Contains,
          { string: new Date().toISOString() }
        )
      )
      break
    }

    case FILTERS.EXCLUDE_ARCHIVED: {
      filtersInput.push({
        attribute: RedemptionQueryAttributes.ArchivedAt,
        op: PageFilterOp.Empty,
        value: { boolean: true },
      })

      break
    }

    default: {
      break
    }
  }

  return { orderByInput: orderByInput, filtersInput: filtersInput }
}

export const sortValues = {
  [SORT_BY.PRICE_HIGH_TO_LOW]: {
    attribute: RedemptionQueryAttributes.Price,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.PRICE_LOW_TO_HIGH]: {
    attribute: RedemptionQueryAttributes.Price,
    direction: PageOrderDirection.Asc,
  },
  [SORT_BY.MOST_POPULAR]: {
    attribute: RedemptionQueryAttributes.RedeemCount,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.MOST_VIEWED]: {
    attribute: RedemptionQueryAttributes.ViewCount,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.CLOSEST]: {
    attribute: RedemptionQueryAttributes.RadiusKm,
    direction: PageOrderDirection.Asc,
  },
}

// Generates a filter object for the flop query
export const generateFlopFilter = (
  attribute: RedemptionQueryAttributes,
  op: PageFilterOp,
  value: PageFilterValues
): RedemptionFiltersInput => {
  const result = {
    attribute: attribute,
    op: op,
    value: value,
  }

  return result
}
