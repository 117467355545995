import { useAuth } from '@hooks/useAuth'
import { AuthenticatedLayout } from './AuthenticatedLayout'
import { UnauthenticatedLayout } from './unauthenticated/Layout.unauthenticated'

export function Layout(
  props: React.JSX.IntrinsicAttributes & { children: React.ReactNode }
) {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return <UnauthenticatedLayout {...props} />
  }

  return <AuthenticatedLayout {...props} />
}
