import { faFire } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SwayCashIcon } from '@icons/SwayCash'
import { Button, Flex, Stack, Text, Title } from '@mantine/core'
import { BOLD, SEMI_BOLD } from '@util/utils'

export const ChangelogModalContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack align="center" h="100%" justify="center" gap={28} pos="relative">
      <FontAwesomeIcon
        icon={faFire}
        color="white"
        size="xl"
        className="h-24 w-24"
      />
      <Title order={4} c="white" fw={BOLD} size="xl" className="text-center">
        Come back daily and earn a bonus!
      </Title>
      <Flex c="white" direction="column" align="center">
        <Text mb={6} fw={SEMI_BOLD} size="lg">
          Daily Rewards:
        </Text>
        <Flex mb={2} align="center" gap={4}>
          7-day streak ={' '}
          <span className="inline-block h-6 w-6 rounded-full bg-white">
            <SwayCashIcon className="h-full w-full" />
          </span>{' '}
          0.50 bonus
        </Flex>
        <Flex align="center" gap={4}>
          14-day streak ={' '}
          <span className="inline-block h-6 w-6 rounded-full bg-white">
            <SwayCashIcon className="h-full w-full" />
          </span>{' '}
          1.00 bonus
        </Flex>
      </Flex>

      <Stack w="100%" gap={18}>
        <Button fullWidth color="deep-blue.9" size={'md'} onClick={onClose}>
          Go to Feed
        </Button>
      </Stack>
    </Stack>
  )
}
